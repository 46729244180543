import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { Router } from 'src/constants';
import UserService from 'src/services/user';
import AuthService from 'src/services/auth';
import infoStore from 'src/stores/infoStore';
import { Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import styles from './styles.module.scss';

export default class LoginVM {
  router;
  @observable isLoading = false;
  @observable phone;
  @observable password;

  constructor(router) {
    makeObservable(this);
    this.router = router;
  }

  didMount = () => {};


  checkProfile = (profile) => {
    if (AuthService.hasToken() && profile) {
      this.router.navigate(Router.Client.Home);
    }
  };


  signIn = async () => {
    if (!this.phone || !this.password) {
      infoStore.show({
        message: '請輸入帳號/密碼',
        type: 'warn'
      });
      return;
    }

    try {
      runInAction(() => { this.isLoading = true; });
      await UserService.login(this.phone, this.password);

      runInAction(() => { this.isLoading = false; });
      this.router.navigate(Router.Client.User);


    } catch (err) {
      runInAction(() => { this.isLoading = false; });
      let _message = '';
      switch (err?.response?.status) {
        case 403:
          Modal.info({
            title: null,
            icon: null,
            content: (
              <div className={styles.infoModalContent}>
                <div className={styles.titleRow}>
                  <ExclamationCircleFilled className={styles.icon} />
                  <div className={styles.title}>您的帳號已被停止使用！</div>
                </div>
                <div>
                  您的帳號已被停止使用，若有疑問請洽喵加人客服信箱
                </div>
              </div>
            ),
            okText: '我知道了',
            okButtonProps: { className: styles.okBtn },
            className: styles.infoModal
          });
          return;
        case 404:
        case 401:
          _message = '帳號或密碼錯誤';
          break;
        default:
          _message = '發生錯誤';
      }
      infoStore.show({ message: _message, type: 'warn' });
    }
  };

  @action onPhoneChange = (e) => {
    this.phone = e.target.value;
  };
  @action onPasswordChange = (e) => {
    this.password = e.target.value;
  };
}
