import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Avatar, Dropdown, theme } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { AppStateContext } from 'src/stores';
import useProfile from 'src/hooks/useProfile';
import MessageService from 'src/services/message';
import { Router } from 'src/constants';
import logo from 'src/assets/logo.png';

import MenuIcon from 'src/assets/bf2b7454e319ef35413e3c1757af4dc2.svg';

import NavItem from './NavItem';
import MobileMenu from './MobileMenu';
import styles from './Header.module.scss';
import MemberOnlyModal from '../MemberOnlyModal';
import withProfile from '../withProfile';

const { useToken } = theme;

const NavList = [
  { title: '最新活動', subtitle: 'Events', to: Router.Client.EventList },
  { title: '貓咪列表', subtitle: 'Cat List', to: Router.Client.CatList },
  { title: '關於我們', subtitle: 'About Us', to: Router.Client.AboutUs },
  { title: '幸福回報', subtitle: 'Success Stories', to: Router.Client.SuccessStories },
  { title: '舉報黑名單', subtitle: 'Report', to: Router.Client.Complaint, isMemberOnly: true },
  { title: '常見問題', subtitle: 'FAQ', to: Router.Client.FAQ }
];

const ProfileMenu = [
  { key: 'UserCenter' },
  { key: 'Message' }
];

function Header(props) {
  const { className } = props;

  const { state, actions } = useContext(AppStateContext);

  const [isVisible, setIsVisible] = useState(false);
  const [isMemberOnlyModalOpen, setIsMemberOnlyModalOpen] = useState(false);

  const location = useLocation();
  const profile = useProfile(false);

  const { token } = useToken();

  useEffect(
    () => {
      if (!profile) { return () => {}; }

      const fetch = async () => {
        const { count } = await MessageService.getUnreadCount().catch(() => ({ count: 0 }));
        actions.updateUnreadCount(count);
      };

      fetch();
      return () => {};
    },
    [location.pathname, profile, actions]
  );

  const renderItems = useCallback(
    (menu) => {
      const { items } = menu.props;

      return (
        <div
          className={styles.items}
          style={{
            backgroundColor: token.colorBgElevated,
            boxShadow: token.boxShadowSecondary
          }}
        >
          {
            items.map((item) => {
              switch (item.key) {
                case 'UserCenter':
                  return (
                    <Link to={Router.Client.User}>
                      <div className={styles.item}>
                        <div className={styles.label}>會員中心</div>
                      </div>
                    </Link>
                  );
                case 'Message':
                  return (
                    <Link to={Router.Client.MessageBox}>
                      <div className={styles.item}>
                        <div className={styles.label}>我的留言板</div>
                        { state.unreadCount > 0 ? (<div className={styles.unread} />) : null }
                      </div>
                    </Link>
                  );
                default:
                  return null;
              }
            })
          }
        </div>
      );
    },
    [state.unreadCount, token]
  );

  const isHome = location.pathname === Router.Client.Home || location.pathname === '/';

  return (
    <div className={cn(styles.header, className)}>
      {isVisible && (
        <MobileMenu
          onClose={() => setIsVisible(false)}
          navList={NavList}
          profile={props.profile}
          onOpenMemberOnlyModal={() => setIsMemberOnlyModalOpen(true)}
        />
      )}

      <div className={cn(styles.box, 'header')}>
        <div className={styles.unnamed}>
          <div className={styles.unnamed1}>
            <div onClick={() => setIsVisible(true)} style={{ cursor: 'pointer' }}>
              <img
                className={styles.image}
                src={MenuIcon}
                alt=""
              />
            </div>
            <Link to={Router.Client.Home} className={styles.logo_link}>
              <img src={logo} className={styles.logo} alt="Logo" />
            </Link>

            {
              profile
                ? (
                  <Dropdown
                    overlayClassName={styles.overlay}
                    menu={{ items: ProfileMenu }}
                    dropdownRender={renderItems}
                  >
                    <div className={styles.avatarDropdown}>
                      <Avatar
                        className={styles.avatar}
                        src={profile.avatar}
                        icon={<UserOutlined />}
                      />
                      <div className={styles.name}>{profile.nickname ?? ''}</div>
                      { state.unreadCount > 0 ? (<div className={styles.unread} />) : null }
                    </div>
                  </Dropdown>
                )
                : (
                  <Link to={Router.Auth.Login} className={styles.box1}>
                    <div className={styles.info}>登入/註冊會員</div>
                  </Link>
                )
            }
          </div>

          <div className={styles.navRow}>
            <Link to={Router.Client.Home} className={styles.home}>
              <div className={styles.image1}>
                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill={isHome ? '#00A5B8' : '#3F3F46'} d="M2.99984 18.9954H6.49984V11.9954H13.4998V18.9954H16.9998V8.49544L9.99984 3.24544L2.99984 8.49544V18.9954ZM2.99984 21.3288C2.35817 21.3288 1.80906 21.1005 1.3525 20.6439C0.89517 20.1866 0.666504 19.6371 0.666504 18.9954V8.49544C0.666504 8.126 0.749337 7.776 0.915004 7.44544C1.07989 7.11489 1.30817 6.84266 1.59984 6.62878L8.59984 1.37878C8.81373 1.22322 9.03734 1.10655 9.27067 1.02878C9.504 0.950998 9.74706 0.912109 9.99984 0.912109C10.2526 0.912109 10.4957 0.950998 10.729 1.02878C10.9623 1.10655 11.1859 1.22322 11.3998 1.37878L18.3998 6.62878C18.6915 6.84266 18.9202 7.11489 19.0858 7.44544C19.2507 7.776 19.3332 8.126 19.3332 8.49544V18.9954C19.3332 19.6371 19.1049 20.1866 18.6483 20.6439C18.191 21.1005 17.6415 21.3288 16.9998 21.3288H11.1665V14.3288H8.83317V21.3288H2.99984Z" />
                </svg>
              </div>
              <div className={cn(styles.info1, { [styles.activeSubtitle]: isHome })}>Home</div>
              {isHome && <div className={styles.active} />}
            </Link>

            {NavList.map((nav) => {
              const isActive = new RegExp(`^(${nav.to}$|${nav.to}/)`, 'i').test(location.pathname);

              return (
                <Link
                  to={nav.to}
                  key={nav.title}
                  className={styles.content_box}
                  onClick={(e) => {
                    if (nav.isMemberOnly && !props.profile) {
                      setIsMemberOnlyModalOpen(true);
                      e.preventDefault();
                    }
                  }}
                >
                  <NavItem
                    title={nav.title}
                    subTitle={nav.subtitle}
                    isActive={isActive}
                  />
                  { isActive && <div className={styles.active} /> }
                </Link>
              );
            })}
          </div>
        </div>
      </div>

      <MemberOnlyModal
        open={isMemberOnlyModalOpen}
        onClose={() => setIsMemberOnlyModalOpen(false)}
      />
    </div>
  );
}

Header.propTypes = {
  className: PropTypes.string
};

export default withProfile(Header, false);
