import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { intersectionObserverOption } from 'src/utils';
import GridCard from './components/GridCard';
import styles from './styles.module.scss';


@observer
class Main extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.props.onNextPage();
      }
    }, intersectionObserverOption);
  }

  componentDidMount() {
    this.observer.observe(this.ref.current);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    return (
      <div className={cn(styles.root, 'success-stories')}>

        {this.props.groups.map((group, gIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.gridBox} key={gIndex}>
            {group.map((item, index) => {
              if (item) {
                return (
                  <div
                    key={item.id}
                    onClick={() => this.props.openModal(item)}
                    className={styles[`component${index}`]}
                  >
                    <GridCard
                      item={item}
                      onReaction={this.props.onReaction}
                    />
                  </div>
                );
              }
              return <div />;
            })}
          </div>
        ))}

        <div ref={this.ref} style={{ height: 10 }} />
      </div>

    );
  }
}

Main.propTypes = {
  onNextPage: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  onReaction: PropTypes.func.isRequired
};

export default Main;
