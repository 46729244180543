import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';

import styles from './styles.module.scss';

const ComplaintGuidelinesModal = (props) => {
  const {
    open,
    onClose = () => {}
  } = props;

  return (
    <Modal
      closeIcon={null}
      onCancel={onClose}
      open={open}
      title={(
        <h4 className={styles.title}>
          說明與注意事項
        </h4>
      )}
      footer={(
        <div className={clsx(styles.row, styles.footer)}>
          <Button
            className={styles.yesBtn}
            onClick={onClose}
          >
            我知道了
          </Button>
        </div>
      )}
      closable
      width="min(950px, 90vw)"
      centered
    >
      <div className={styles.content}>
        <p>
          提醒！「喵加人認養媒合平台」並無實質公權力，本黑名單舉報功能意在揭露「可能」存在不當行為的出、認養人，供平台會員作為參考。
        </p>
        <div className={styles.section}>
          <h5 className={styles.sectionTitle}>
            舉報黑名單的作用
          </h5>
          <p>
            「喵加人認養媒合平台」會將舉報名單以及調查結果納入網站資料庫。未來若會員以曾被舉報的個人資料在本平台上進行認養、出養行為，本平台將會向與其相關的出養方、認養人揭露該會員曾被舉報的事件以及調查結果（舉報人的資訊不會公開）。
          </p>
        </div>
        <div className={styles.section}>
          <h5 className={styles.sectionTitle}>
            注意事項
          </h5>
          <ul className={styles.bulletList}>
            <li>
              「喵加人認養媒合平台」目前僅調查有飼主的貓咪案件，若為流浪動物疑似有不良狀況，請尋求其他團體或當地動保主管機關協助。
            </li>
            <li>
              「喵加人認養媒合平台」因人力、動物安置空間有限，且調查人員有一定排程，無法受理即時救援的案件，若動物有危及生命狀況，且您無法在現場自行提供協助，請優先通報當地動保處或防疫所，或是撥打1959動物保護專線(各縣市皆為此專線)。
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  );
};

ComplaintGuidelinesModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default ComplaintGuidelinesModal;
