import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

function _renderBreadcrumbItem(item) {
  return item.url ? (
    <Link to={item.url}>{ item.title }</Link>
  ) : (<span>{ item.title }</span>);
}

export default function BackstageBreadcrumb({ items = [] }) {
  return (
    <Breadcrumb
      items={items}
      itemRender={_renderBreadcrumbItem}
    />
  );
}
