import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { RightCircleFilled } from '@ant-design/icons';

import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import { intersectionObserverOption } from 'src/utils';
import { ComplaintLevelTxt, ComplaintStatusTxt, Router } from 'src/constants';
import ComplaintVM from './viewModel';
import styles from './styles.module.scss';

@observer
class ComplaintPage extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.vm = new ComplaintVM(this.props.router);
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.vm.onNextPage();
      }
    }, intersectionObserverOption);
  }

  componentDidMount() {
    this.vm.didMount();
    this.observer.observe(this.ref.current);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  renderComplaintTable() {
    const columns = [
      {
        title: '舉報時間',
        dataIndex: 'date',
        key: 'date',
        className: styles.dateColumn,
        ellipsis: true
      },
      {
        title: '舉報對象',
        dataIndex: 'targetName',
        key: 'targetName'
      },
      {
        title: '嚴重等級',
        key: 'level',
        render: (record) => (
          <div className={clsx(styles.levelTag, styles[record.level])}>
            {record.level ? ComplaintLevelTxt[record.level] : '-'}
          </div>
        )
      },
      {
        title: '調查更新日期',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        className: styles.updatedAtColumn
      },
      {
        title: '調查狀態',
        key: 'status',
        render: (record) => (
          <div className={clsx(styles.statusTag, styles[record.status])}>
            {ComplaintStatusTxt[record.status]}
          </div>
        )
      }
    ];

    return (
      <Table
        dataSource={this.vm.dataSource}
        columns={columns?.filter((column) => !!column)}
        pagination={false}
        rowClassName={styles.tableRow}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => this.vm.toDetail(record.id)
          };
        }}
        rowKey={(record) => record.id}
        className={styles.complaintsTable}
      />
    );
  }

  renderComplaintButton = () => {
    return (
      <Link
        to={Router.Client.ComplaintCreate}
        className={styles.reportButton}
      >
        <span>
          舉報黑名單
        </span>
        <RightCircleFilled />
      </Link>
    );
  };

  renderMainContent = () => {
    if (this.vm.isPageLoading) {
      return <Loading />;
    }

    if (!this.vm.hasComplaint) {
      return (
        <div className={styles.noDataBox}>
          <div className={styles.noData}>
            目前沒有資料
          </div>
          {this.renderComplaintButton()}
        </div>
      );
    }

    return (
      <>
        {this.renderComplaintButton()}
        <div className={styles.mainContent}>
          {this.vm.hasComplaint ? this.renderComplaintTable() : this.renderNoData()}
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        <Breadcrumb
          items={[
            { title: '會員中心', link: Router.Client.User },
            { title: '舉報黑名單' }
          ]}
        />

        <div className={styles.main}>
          <div className={styles.box}>
            {this.renderMainContent()}
            <div ref={this.ref} style={{ height: 10 }} />
          </div>
        </div>
      </>
    );
  }
}

ComplaintPage.propTypes = {
  router: PropTypes.object
};

export default withRouter(withProfile(ComplaintPage, true));
