import React from 'react';
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';
import { AppStateContext } from 'src/stores';
import { Router } from 'src/constants';

import ErrorPage from './ErrorPage';

// client
import ClientLayout from '../components/ClientLayout';
import Home from './client/Home';
import AboutUs from './client/AboutUs';
import PreviewContract from './client/PreviewContract';
import SuccessStories from './client/SuccessStories';
import MessagePage from './client/Message';
import MessageBoxPage from './client/MessageBox';
import PrivacyPolicy from './client/PrivacyPolicy';
import FAQ from './client/FAQ';
import Help from './client/Help';
import CommentReply from './client/CommentReply';

import EventList from './client/Events/EventList';
import NewsItem from './client/Events/NewsItem';
import RaffleEventItem from './client/Events/RaffleEventItem';

import UserCenter from './client/User/UserCenter';
import UserProfile from './client/User/Profile';
import UserRestPwd from './client/User/RestPwd';
import UserEvent from './client/User/Event';
import UserApplicationList from './client/User/ApplicationList';
import UserApplicationItem from './client/User/ApplicationItem';
import UserFosterList from './client/User/FosterList';
import UserFosterItem from './client/User/FosterItem';
import UserFosterItemApplication from './client/User/FosterItemApplication';
import UserReport from './client/User/Report';
import UserComment from './client/User/Comment';
import UserComplaint from './client/User/Complaint';
import UserComplaintCreate from './client/User/ComplaintCreate';
import UserComplaintDetail from './client/User/ComplaintDetail';

import CatList from './client/Cat/CatList';
import CatItem from './client/Cat/CatItem';
import CatApplication from './client/Cat/CatApplication';


// auth
import Login from './auth/Login';
import Verify from './auth/Verify';
import RestPwd from './auth/RestPwd';
import Register from './auth/Register';

// backstage
import BackstageLayout from '../components/BackstageLayout';
import UserListPage from './backstage/UserList';
import UserProfilePage from './backstage/UserProfile';
import FosterPage from './backstage/Foster';
import ReportPage from './backstage/Report';
import RaffleListPage from './backstage/RaffleList';
import RaffleEventPage from './backstage/RaffleEvent';
import BannerPage from './backstage/Banner';
import NewsPage from './backstage/News';
import AboutUsPage from './backstage/AboutUs';
import FAQPage from './backstage/FAQ';
import HelpPage from './backstage/Help';
import ComplaintPage from './backstage/Complaint';
import ComplaintDetailPage from './backstage/ComplaintDetail';

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorPage />,
    children: [
      {
        element: <ClientLayout />,
        children: [
          // auth
          { path: Router.Auth.Login, element: <Login /> },
          { path: Router.Auth.Verify, element: <Verify /> },
          { path: Router.Auth.RestPwd, element: <RestPwd /> },
          { path: Router.Auth.Register, element: <Register /> },

          // others
          { path: Router.Client.Home, element: <Home /> },
          { path: Router.Client.AboutUs, element: <AboutUs /> },
          { path: Router.Client.CommentReply, element: <CommentReply /> },
          { path: Router.Client.SuccessStories, element: <SuccessStories /> },
          { path: Router.Client.Message, element: <MessagePage /> },
          { path: Router.Client.MessageBox, element: <MessageBoxPage /> },
          { path: Router.Client.PrivacyPolicy, element: <PrivacyPolicy /> },
          { path: Router.Client.FAQ, element: <FAQ /> },
          { path: Router.Client.Help, element: <Help /> },

          // events and news
          { path: Router.Client.EventList, element: <EventList /> },
          { path: Router.Client.NewsItem, element: <NewsItem /> },
          { path: Router.Client.EventItem, element: <RaffleEventItem /> },

          // cats
          { path: Router.Client.CatList, element: <CatList /> },
          { path: Router.Client.CatItem, element: <CatItem /> },
          { path: Router.Client.CatApplication, element: <CatApplication /> },

          // user
          { path: Router.Client.User, element: <UserCenter /> },
          { path: Router.Client.Profile, element: <UserProfile /> },
          { path: Router.Client.RestPwd, element: <UserRestPwd /> },
          { path: Router.Client.UserEvent, element: <UserEvent /> },
          { path: Router.Client.Report, element: <UserReport /> },
          { path: Router.Client.FosterList, element: <UserFosterList /> },
          { path: Router.Client.CreateFosterItem, element: <UserFosterItem /> },
          { path: Router.Client.FosterItem, element: <UserFosterItem /> },
          { path: Router.Client.FosterItemApplication, element: <UserFosterItemApplication /> },
          { path: Router.Client.ApplicationList, element: <UserApplicationList /> },
          { path: Router.Client.ApplicationItem, element: <UserApplicationItem /> },
          { path: Router.Client.Comment, element: <UserComment /> },
          { path: Router.Client.CommentUser, element: <UserComment /> },
          { path: Router.Client.Complaint, element: <UserComplaint /> },
          { path: Router.Client.ComplaintCreate, element: <UserComplaintCreate /> },
          { path: Router.Client.ComplaintDetail, element: <UserComplaintDetail /> },

          // others
          { path: '/', element: <Home /> }
        ]
      },
      { path: Router.Client.PreviewContract, element: <PreviewContract /> },
      {
        path: '*',
        loader: () => { return redirect(Router.Client.Home); }
      }
    ]
  },
  {
    path: '/backstage',
    element: <BackstageLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/backstage',
        loader: () => { return redirect(Router.Backstage.User); }
      },
      { path: Router.Backstage.User, element: <UserListPage /> },
      { path: Router.Backstage.UserItem, element: <UserProfilePage /> },
      { path: Router.Backstage.Foster, element: <FosterPage /> },
      { path: Router.Backstage.Report, element: <ReportPage /> },
      { path: Router.Backstage.Raffle, element: <RaffleListPage /> },
      { path: Router.Backstage.CreateRaffleEvent, element: <RaffleEventPage /> },
      { path: Router.Backstage.RaffleEvent, element: <RaffleEventPage /> },
      { path: Router.Backstage.Banner, element: <BannerPage /> },
      { path: Router.Backstage.News, element: <NewsPage /> },
      { path: Router.Backstage.AboutUs, element: <AboutUsPage /> },
      { path: Router.Backstage.FAQ, element: <FAQPage /> },
      { path: Router.Backstage.Help, element: <HelpPage /> },
      { path: Router.Backstage.Complaint, element: <ComplaintPage /> },
      { path: Router.Backstage.ComplaintDetail, element: <ComplaintDetailPage /> }
    ]
  }
], {
  basename: '/'
});



class PagesRoot extends React.Component {
  static contextType = AppStateContext;

  render() {
    return (
      <RouterProvider router={router} />
    );
  }
}

export default PagesRoot;
