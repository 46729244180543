import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import dayjs from 'dayjs';
import infoStore from 'src/stores/infoStore';
import ComplaintService from 'src/services/complaint';

export default class ComplaintDetailVM {
  complaintId = null;
  profile = null;
  @observable isPageLoading = false;
  @observable complaint = null;

  constructor(complaintId, profile) {
    makeObservable(this);
    this.complaintId = complaintId;
    this.profile = profile;
  }

  didMount = async () => {
    try {
      runInAction(() => { this.isPageLoading = true; });

      const complaint = await ComplaintService.getDetail(this.complaintId);

      // check user permission
      if (this.profile.isAdmin || this.profile.email === complaint.contact.email) {
        runInAction(() => { this.hasPermission = true; });
      } else {
        runInAction(() => { this.isPageLoading = false; });
        return;
      }

      runInAction(() => {
        this.complaint = complaint;
        this.isPageLoading = false;
      });

    } catch {
      runInAction(() => { this.isPageLoading = false; });
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }

  };

  @computed get status() {
    return this.complaint?.result?.status;
  }
}
