import { makeObservable, observable, action } from 'mobx';
import { ComplaintStatus } from 'src/constants';

class TabsState {
  @observable backstageComplaint = ComplaintStatus.NotStarted;

  constructor() {
    makeObservable(this);
  }

  @action updateBackstageComplaint = (value) => {
    this.backstageComplaint = value;
  };
}

export default new TabsState();
