import { makeObservable, observable, action, runInAction, computed } from 'mobx';
import { message } from 'antd';
import UserService from 'src/services/user';
import infoStore from 'src/stores/infoStore';
import { Router } from 'src/constants';
import ComplaintService from 'src/services/complaint';
import { clientConfirmStore } from 'src/stores/confirmStore';
import { Attachment } from './Upload';

const MAX_IMAGE_MB = 10;
const MAX_PDF_MB = 10;
const MAX_VIDEO_MB = 25;
export const MAX_FILE_COUNT = 10;

const isFileValid = (file) => {
  let valid = false;
  switch (file.type) {
    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/bmp':
      if (file.size < MAX_IMAGE_MB * 1024 * 1024) {
        valid = true;
      }
      break;
    case 'audio/mp3':
    case 'video/mp3':
    case 'audio/mp4':
    case 'video/mp4':
      if (file.size < MAX_VIDEO_MB * 1024 * 1024) {
        valid = true;
      }
      break;
    case 'application/pdf':
      if (file.size < MAX_PDF_MB * 1024 * 1024) {
        valid = true;
      }
      break;
    default:
  }
  return valid;
};

export default class ComplaintCreateVM {
  form;
  router;
  @observable isMounted = false;
  @observable isGuidelinesModalOpen = false;
  @observable files = {};

  @observable isLoading = false;

  constructor(form, router) {
    makeObservable(this);
    this.form = form;
    this.router = router;
  }

  didMount = async () => {
    this.openGuidelinesModal();

    try {
      const profile = await UserService.profile();
      this.form.setFieldsValue({
        contactPhone: profile.phone,
        contactEmail: profile.email
      });
      runInAction(() => {
        this.isMounted = true;
      });

    } catch {
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };


  onClickSubmit = async () => {
    try {
      await this.form.validateFields();
    } catch (error) {
      const content = ['contactPhone', 'targetPhone'].includes(error.errorFields[0].name[0]) ? '請填入有效的電話格式' : '必填欄位未填';
      message.open({ type: 'error', content });
      return;
    }

    clientConfirmStore.show(({
      title: '確定要提交黑名單舉報嗎？',
      message: '確認後不可收回，確認要繼續嗎？',
      callBack: this.handleSubmit
    }));
  };

  handleSubmit = async () => {
    try {
      runInAction(() => {
        this.isLoading = true;
      });

      const complaint = this.form.getFieldsValue();

      const complaintData = {
        target: {
          name: complaint.targetName,
          phone: complaint.targetPhone,
          idCard: complaint.targetIdCard
        },
        event: {
          date: complaint.eventDate,
          location: complaint.eventLocation,
          issues: complaint.eventIssues,
          description: complaint.eventDescription,
          files: this.filesToPost
        },
        contact: {
          phone: complaint.contactPhone,
          email: complaint.contactEmail
        }
      };

      await ComplaintService.create(complaintData);

      message.open({ type: 'success', content: '已舉報！' });
      this.router.navigate(Router.Client.Complaint);

    } catch (error) {
      console.log('error', error);
      infoStore.show({ message: '發生錯誤，無法提交舉報', type: 'warn' });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action onFileSelected = async (event, context) => {
    const file = event.file;
    const valid = isFileValid(file);

    if (!valid) {
      const maxSize = file.type.includes('video') ? MAX_VIDEO_MB : MAX_IMAGE_MB;
      message.open({ type: 'warning', content: `檔案 "${file.name}" 超過 ${maxSize} MB` });
      return;
    }

    this.files[file.name] = Attachment.editmode(file);
  };

  @action onFileRemove = (item) => {
    this.files[item.origin === 'server' ? item.name : item.file.name] = null;
  };

  @computed get fileList() {
    return Object.values(this.files).filter((el) => !!el);
  }

  @computed get filesToPost() {
    return Object.values(this.files).filter((el) => !!el).map((el) => ({
      size: el.origin === 'server' ? el.size : el.file.size,
      name: el.origin === 'server' ? el.name : el.id
    }));
  }

  @action openGuidelinesModal = () => {
    this.isGuidelinesModalOpen = true;
  };

  @action closeGuidelinesModal = () => {
    this.isGuidelinesModalOpen = false;
  };

  validatePhone = async (_, value, isMobile = true) => {
    const regex = isMobile ? /09\d{8}/g : /\d{5}/g;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject(new Error(isMobile ? '請填入有效的手機號碼' : '請填入有效的電話號碼'));
  };
}
