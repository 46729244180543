import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';


function GridTableItem(props) {
  return (
    <div
      className={clsx(styles.item, props.className)}
      style={{ '--span': props.span ?? 1 }}
    >
      <div className={clsx(styles.key, props.labelClassName)}>{props.title}</div>
      <div className={styles.value}>{props.value ?? '-'}</div>
    </div>
  );
}

GridTableItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  span: PropTypes.number
};

export default GridTableItem;
