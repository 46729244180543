import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Steps, Button, Input, Modal } from 'antd';
import { ExportOutlined } from '@ant-design/icons';

import { Router, TaiwanCountyTxt } from 'src/constants';
import withRouter from 'src/components/withRouter';
import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import ContractEmbeded from 'src/components/ContractEmbeded';
import CatApplicationVM from './viewModel';

import styles from './styles.module.scss';

@observer
class CatApplicationPage extends React.Component {
  constructor(props) {
    super(props);

    const fosterId = this.props.router.params.fosterId;

    this.vm = new CatApplicationVM({
      fosterId,
      navigate: this.props.router.navigate
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    const foster = this.vm.foster;

    return (
      <>
        <Breadcrumb
          items={[
            { title: '貓咪列表', link: Router.Client.CatList },
            { title: foster?.name ?? '' }
          ]}
        />

        {this.vm.isPageLoading
          ? <Loading />
          : (
            <div className={styles.main}>
              <div className={styles.box}>

                <div className={styles.leftCol}>
                  <div className={styles.imgBox}>
                    <img className={styles.img} src={foster?.images?.[0]} alt="cat" />
                  </div>
                </div>

                <div className={styles.rightCol}>
                  <div className={styles.name}>{foster?.name ?? ''}</div>
                  <div className={styles.text}>
                    {`出養人：${foster?.fosterPerson.nickname ?? ''} / 地區：${TaiwanCountyTxt[foster?.fosterPerson.region] ?? ''}`}
                  </div>

                  <Steps
                    current={this.vm.currentStep}
                    className={styles.steps}
                    progressDot
                    items={[
                      { title: <div className={styles.items}>{'檢視\n認養合約'}</div> },
                      { title: <div className={styles.items}>{'認養申請\n留言'}</div> },
                      { title: <div className={styles.items}>{'申請成功\n待出養者聯繫'}</div> }
                    ]}
                  />

                  {this.vm.currentStep === 0 && (
                    <>
                      <div className={styles.text}>下開合約非最終版，簽約前雙方保有討論與修改權力，若有疑問請洽出養方聯繫討論</div>

                      <ContractEmbeded foster={foster} />

                      <div className={styles.btnBox}>
                        <Button className={styles.btn} onClick={this.vm.nextStep}>
                          下一步
                        </Button>
                      </div>
                    </>
                  )}


                  {this.vm.currentStep === 1 && (
                  <>
                    <div className={styles.extra}>
                      參考認養申請的
                      <div
                        className={styles.btn}
                        onClick={() => this.vm.toggleModal(true)}
                      >
                        <span className={styles.txt}>
                          填寫範本
                        </span>
                        <ExportOutlined />
                      </div>
                    </div>

                    <Input.TextArea
                      rows={10}
                      maxLength={1000}
                      showCount
                      onChange={this.vm.onMessageChange}
                      value={this.vm.message}
                      placeholder={`建議在此處盡可能清楚描述下列內容，可以增加出養人對您的了解，提高和您聯繫的機率唷！

  —住處持有人（自有或房東）與同住成員是否皆取得共識可飼養貓咪？
  —您的個人自我介紹（年齡/性別/職業....）
  —您過往照顧貓咪或相處的經驗
  —您對飼養貓咪的相關理解（貓咪飲食、醫療＆相關費用、活動空間、現行動保相關法令...等）
  —您想認養這隻貓咪的主要原因
  —出養人方便與您聯繫的時間
                    `}
                    />

                    <div className={styles.btnBox}>
                      <Button className={styles.btn} onClick={this.vm.apply}>
                        送出申請
                      </Button>
                    </div>
                  </>
                  )}

                </div>
              </div>
            </div>
          )}

        <Modal
          open={this.vm.isModalShow}
          closeIcon={null}
          footer={null}
        >
          <div className={styles.modal}>
            <div className={styles.modal_title}>
              認養申請留言範本
            </div>
            <div className={styles.modal_body}>
              {`您好！我是Sophia，很希望有機會向您認養小咪。我目前是自由接案工作者，在家中的時間很長，家裡四位成員都喜歡貓咪，也有養貓的經驗，過去一直都有貓咪陪伴我們，照顧貓咪的時間大約有十年了吧。對於相關費用以及飲食都有了解。我們家貓咪吃濕食，使用木屑砂。\n
              目前的房子是租屋，透天三層樓，房東不限制養寵物，因一直都有養貓，基本門窗防護都有。\n
              想跟您認養小咪是因為希望能夠再找到有緣的貓咪陪伴。覺得小咪很合眼緣，希望有機會多認識她。若電話聯繫時間可以在10:00-20:00，謝謝。`}
            </div>
            <Button onClick={() => this.vm.toggleModal(false)} className={styles.modal_btn}>
              關閉
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

CatApplicationPage.propTypes = {
  router: PropTypes.object.isRequired
};

export default withRouter(CatApplicationPage);
