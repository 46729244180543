import React from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Card from 'src/components/Card';
import { Router } from 'src/constants';
import { parseDate, intersectionObserverOption } from 'src/utils';

import styles from '../styles.module.scss';

@observer
class EventTab extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.props.vm.onRaffleEventNextPage();
      }
    }, intersectionObserverOption);
  }

  renderStatusTag = (startAt, endAt) => {
    const now = dayjs();
    if (now.isBefore(dayjs(startAt))) return <div className={cn(styles.tag, styles.tagEventNotStart)}>尚未開始</div>;
    if (now.isAfter(dayjs(endAt))) return <div className={cn(styles.tag, styles.tagEventEnded)}>已截止</div>;
    return <div className={cn(styles.tag, styles.tagEventOngoing)}>報名中</div>;
  };

  componentDidMount() {
    this.observer.observe(this.ref.current);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    return (
      <div className={styles.card_box}>
        {this.props.vm.raffleEvent.list.map((r) => (
          <Card
            key={r.id}
            isImage3to2
            to={`${Router.Client.EventList}/${r.id}`}
            image={r?.images?.[0]}
            title={r?.title ?? ''}
            content={(
              <div className={styles.card_content}>
                {r?.plainTextContent ?? ''}
              </div>
                    )}
            footer={(
              <div className={styles.row}>
                <div className={styles.col}>
                  <div className={styles.title}>報名時間</div>
                  <div className={styles.date}>{`${parseDate(r.startAt, '', true)} - ${parseDate(r.endAt, '', true)}`}</div>
                </div>
                {this.renderStatusTag(r.startAt, r.endAt)}
              </div>
            )}
          />
        ))}
        <div ref={this.ref} />
      </div>
    );
  }
}

EventTab.propTypes = {
  vm: PropTypes.object.isRequired
};

export default EventTab;
