import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { Spin } from 'antd';
import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';

import styles from './styles.module.scss';

const getDescription = (item, isLengthLimitReached) => {
  if (isLengthLimitReached) { return '檔案數量已達上限'; }
  if (item.status === 'error') { return '上傳失敗'; }
  return null;
};

const FileCard = observer((props) => {
  const {
    item,
    isLengthLimitReached,
    onClear = () => {}
  } = props;

  const onClearProxy = useCallback(() => onClear(item), [onClear, item]);

  useEffect(
    () => {
      if (!isLengthLimitReached && item.file) {
        item.start();
      }

      return () => {
        if (item.file) {
          item.stop();
        }
      };
    },
    [item, isLengthLimitReached]
  );

  const isFailed = isLengthLimitReached || item.status === 'error';

  const description = getDescription(item, isLengthLimitReached);

  return (
    <div
      className={clsx(styles.card)}
    >
      <div
        className={clsx(styles.topHalf, (item.clickable || item.url) && styles.clickable)}
        onClick={item.download || (() => window.open(item.url))}
      >
        <PaperClipOutlined className={styles.paperclip} />
        <div className={clsx(styles.info, item.loading && styles.opacity20)}>
          <div className={clsx('wrap', styles.name, isFailed && styles.failed)}>{ item.name }</div>
          {description && (
            <div className={clsx(styles.text, isFailed && styles.failed)}>
              {description}
            </div>
          )}
        </div>
      </div>
      <div className={styles.endHalf}>
        <Spin
          className={styles.spin}
          spinning={!isFailed && !!item.loading && item.origin !== 'server'}
        />
        <DeleteOutlined className={styles.delete} onClick={onClearProxy} />
      </div>
    </div>
  );
});

FileCard.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  isLengthLimitReached: PropTypes.bool,
  onClear: PropTypes.func
};

export default FileCard;
