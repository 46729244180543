import dayjs from 'dayjs';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { Router } from 'src/constants';
import ComplaintService from 'src/services/complaint';
import infoStore from 'src/stores/infoStore';

export default class ComplaintVM {
  router;

  limit = 40;
  anchor = null;
  @observable list = [];
  @observable isLoading = false;
  @observable isPageLoading = false;

  constructor(router) {
    makeObservable(this);
    this.router = router;
  }

  @computed get hasComplaint() {
    return this.list.length > 0;
  }

  @computed get dataSource() {
    return this.list.map((item) => {
      return {
        id: item.id,
        date: dayjs(item.createdAt).format('YYYY-MM-DD HH:mm'),
        targetName: item.target.name,
        level: item.result.level,
        updatedAt: item.result.date ? dayjs(item.result.date).format('YYYY-MM-DD') : '-',
        status: item.result.status
      };
    });
  }

  didMount = async () => {
    try {
      runInAction(() => { this.isPageLoading = true; });

      const { list, anchor } = await ComplaintService.getList({
        limit: this.limit
      });

      runInAction(() => {
        this.list = list;
        this.anchor = anchor;
      });
    } catch (err) {
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    } finally {
      runInAction(() => { this.isPageLoading = false; });
    }
  };


  onNextPage = async () => {
    if (this.isLoading || !this.anchor) return;

    runInAction(() => { this.isLoading = true; });

    try {
      const { list, anchor } = await ComplaintService.getList({
        anchor: this.anchor,
        limit: this.limit
      });

      runInAction(() => {
        this.list = this.list.concat(list);
        this.anchor = anchor;
        this.isLoading = false;
      });
    } catch {
      runInAction(() => { this.isLoading = false; });
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };

  toDetail = (id) => {
    this.router.navigate(`${Router.Client.Complaint}/${id}`);
  };
}
