import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { PaperClipOutlined } from '@ant-design/icons';
import { parseDate } from 'src/utils';
import { ComplaintIssueTxt } from 'src/constants';
import GridTable from 'src/components/GridTable';

import styles from './styles.module.scss';

const ComplaintContentTable = (props) => {
  const { complaint, layout = 'backstage' } = props;

  if (!complaint) {
    return null;
  }

  const renderTargetSection = () => {
    const { target } = complaint;
    return (
      <section className={styles.section}>
        <div className={styles.sectionTitle}>第一部分：提報對象資料</div>
        <div className={styles.content}>
          <GridTable
            shortList={[
              { key: '姓名', value: target.name },
              { key: '身份證字號/護照號碼', value: target.idCard || '-' },
              { key: '手機號碼', value: target.phone || '-' }
            ]}
            shortListClassName={styles.reporteeGrid}
            labelClassName={styles.label}
          />
        </div>
      </section>
    );
  };

  const renderEventSection = () => {
    const { event } = complaint;
    return (
      <section className={styles.section}>
        <div className={styles.sectionTitle}>第二部分：事件內容</div>
        <div className={styles.content}>
          <GridTable
            shortList={[
              { key: '發生時間', value: parseDate(event.date) },
              { key: '發生地區', value: event.location }
            ]}
            shortListClassName={styles.contentShortListGrid}
            longList={[
              {
                key: '主要問題',
                value: (
                  <div className={styles.issues}>
                    {event.issues?.map((issue, index) => (
                      <div
                        key={issue}
                        className={styles.issue}
                      >
                        {ComplaintIssueTxt[issue] ?? issue}
                      </div>
                    ))}
                  </div>
                )
              },
              { key: '事件描述', value: event.description },
              {
                key: '事件舉證影像/檔案',
                value: (
                  <div className={styles.files}>
                    {event.files?.length
                      ? event.files.map((file, index) => (
                        <a
                          key={file.name}
                          className={styles.file}
                          href={file.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <PaperClipOutlined className={styles.paperclip} />
                          <span>{file.name}</span>
                        </a>
                      ))
                      : '-'}
                  </div>
                )
              }
            ]}
          />
        </div>
      </section>
    );
  };

  const renderReporterSection = () => {
    const { reporter, contact } = complaint;
    const isLayoutBackstage = layout === 'backstage';
    return (
      <section className={styles.section}>
        <div className={styles.sectionTitle}>
          第三部分：
          {isLayoutBackstage ? '提報人的聯絡資訊' : '您的聯絡資訊'}
        </div>
        <div className={styles.content}>
          <GridTable
            shortList={[
              ...isLayoutBackstage ? [
                { key: '提報人會員姓名', value: reporter.name },
                { key: '會員帳號', value: reporter.phone }
              ] : [],
              { key: '聯絡電話', value: contact.phone },
              { key: 'Email', value: contact.email }
            ]}
          />
        </div>
        <div className={styles.reportedAt}>
          舉報時間：
          {parseDate(complaint.createdAt)}
        </div>
      </section>
    );
  };

  return (
    <>
      {renderTargetSection()}
      {renderEventSection()}
      {renderReporterSection()}
    </>
  );
};

ComplaintContentTable.propTypes = {
  complaint: PropTypes.object,
  layout: PropTypes.string
};

export default observer(ComplaintContentTable);
