import { action, computed, makeObservable, observable } from 'mobx';

import AntdTablePager from 'src/models/AntdTablePager';
import errorStore from 'src/stores/errorStore';
import { ComplaintStatus } from 'src/constants';
import ComplaintService from 'src/services/complaint';
import TabsState from 'src/stores/TabsState';

export default class ComplaintViewModel {
  pager;

  constructor(router) {
    makeObservable(this);
    this.router = router;
    this.pager = new AntdTablePager(this.query, 20);
  }

  @observable params = {
    status: TabsState.backstageComplaint,
    level: undefined,
    keyword: undefined
  };

  @computed get currentTab() {
    return TabsState.backstageComplaint;
  }

  @computed get paramsToFetch() {
    return {
      ...this.params,
      status: this.currentTab,
      level: this.params.status === ComplaintStatus.Completed ? this.params.level : undefined,
      keyword: this.params.keyword || undefined
    };
  }

  didMount = async () => {
    await this.pager.next(true);
  };

  query = async (limit, anchor) => {
    try {
      const { list, anchor: nextAnchor } = await ComplaintService.getListBackstage({ ...this.paramsToFetch, limit, anchor });
      return { list, anchor: nextAnchor };

    } catch (err) {
      errorStore.show('發生錯誤，無法取得資訊');
      return { list: [], anchor: null };
    }
  };

  @action onTabChange = (value) => {
    TabsState.updateBackstageComplaint(value);
    this.pager.next(true);
  };

  @action onLevelChange = (value) => {
    this.params.level = value;
    this.pager.next(true);
  };

  @action onKeywordChange = (value) => {
    this.params.keyword = value;
  };

  @action onKeywordSearch = (value) => {
    this.params.keyword = value || undefined;
    this.pager.next(true);
  };
}
