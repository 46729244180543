import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import icon from 'src/assets/ff4393ee55f1f25d16e9ede36aadf773.png';
import iconBg from 'src/assets/7fa14fc076409bf5f22be2b89e859945.png';
import styles from './styles.module.scss';

function MenuItem(props) {
  return (
    <div className={styles.menuItem}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.subtitle}>{props.subtitle}</div>
    </div>
  );
}

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

function MobileMenu(props) {
  return (
    <div className={cn(styles.content_box, 'mobile-menu full-height')}>
      <div className={styles.mainWrapper}>
        {props.navList.map((nav) => (
          <Link
            key={nav.title}
            className={styles.menuTitles}
            to={nav.to}
            onClick={(e) => {
              if (nav.isMemberOnly && !props.profile) {
                props.onOpenMemberOnlyModal();
                e.preventDefault();
              }
              props.onClose();
            }}
          >
            <MenuItem title={nav.title} subtitle={nav.subtitle} />
          </Link>
        ))}

        <div
          onClick={props.onClose}
          className={styles['close-button']}
          style={{ '--src': `url(${iconBg})` }}
        >
          <img className={styles.icon} src={icon} alt="" />
        </div>
      </div>
    </div>
  );
}

MobileMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  navList: PropTypes.array.isRequired
};

export default MobileMenu;
