import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Input, Modal } from 'antd';
import { SmileOutlined, FrownOutlined, RightCircleFilled } from '@ant-design/icons';

import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import Loading from 'src/components/Loading';
import { parseDate, intersectionObserverOption } from 'src/utils';
import CommentReplyVM from './viewModel';

import styles from './styles.module.scss';

@observer
class CommentReply extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();

    const { commentPairId, commentId } = this.props.router.params;
    this.vm = new CommentReplyVM({ commentPairId, commentId });

    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.vm.onNextPage();
      }
    }, intersectionObserverOption);
  }

  componentDidMount() {
    this.vm.didMount();
    this.observer.observe(this.ref.current);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const { userId } = this.props.router.params;

    return (
      <>
        <div className={styles.main}>
          <div className={styles.box}>
            {this.vm.isPageLoading
              ? <Loading />
              : (
                <>
                  <div className={styles.disclaimer}>所有評價及留言資料為個別接觸的主觀經驗，僅供關係人參考使用，請勿進行不當傳播，以免觸法。</div>

                  <div className={styles.commentBox}>

                    <div className={styles.row}>
                      <div className={styles.title}>
                        {`
                        ${this.vm.participant.get(userId)}
                        ${userId === this.vm.commentPair?.application.applicantId ? '認養' : '出養'}
                        ${this.vm.commentPair?.foster.name}
                      `}
                      </div>

                      {this.vm.tagTxt && (
                      <div className={cn(styles.tag, { [styles.blueTag]: this.vm.isBlueTag })}>
                        {this.vm.tagTxt}
                      </div>
                      )}
                    </div>

                    <div className={cn(styles.subtitle, styles.blue)}>
                      <div>正面評價</div>
                      <div>
                        {[1, 2, 3, 4, 5].map((index) => (
                          <SmileOutlined
                            key={index}
                            style={{ marginLeft: 5 }}
                            className={cn({ [styles.unSelect]: index > this.vm.comment?.positiveRating ?? 0 })}
                          />
                        ))}
                      </div>
                    </div>

                    <div className={styles.content}>{this.vm.comment?.positiveComment}</div>

                    <div className={styles.dottedDivider} />

                    <div className={cn(styles.subtitle, styles.orange)}>
                      <div>負面評價</div>
                      <div>
                        {[1, 2, 3, 4, 5].map((index) => (
                          <FrownOutlined
                            key={index}
                            style={{ marginLeft: 5 }}
                            className={cn({ [styles.unSelect]: index > this.vm.comment?.negativeRating ?? 0 })}
                          />
                        ))}
                      </div>
                    </div>

                    <div className={styles.content}>{this.vm.comment?.negativeComment}</div>

                    <div className={styles.btnBox}>
                      <div className={styles.date}>{parseDate(this.vm.comment?.createAt, '-')}</div>
                    </div>

                    <div className={styles.solidDivider} />
                  </div>


                  {this.vm.hasReply
                    ? (
                      <div>
                        {this.vm.reply.list.map((reply) => (
                          <div key={reply._id} className={styles.replyBox}>

                            <div className={cn({ [styles.content]: true, [styles.black]: userId === reply.creatorId })}>
                              {reply.content}
                            </div>

                            <div className={styles.footer}>
                              <div>{parseDate(reply.createAt, '')}</div>
                              <div className={cn({ [styles.black]: userId === reply.creatorId })}>
                                {this.vm.participant.get(reply.creatorId)}
                              </div>
                            </div>

                            <div className={styles.dottedDivider} />
                          </div>
                        ))}
                      </div>
                    )
                    : (
                      <div className={styles.noCommentBox}>
                        <div className={styles.noComment}>尚無留言</div>
                      </div>
                    )}

                  {this.vm.canReply(this.props.profile.id) && (
                  <Button
                    className={styles.createBtn}
                    onClick={this.vm.openModal}
                  >
                    新增留言
                    <RightCircleFilled />
                  </Button>
                  )}
                </>
              )}
            <div ref={this.ref} style={{ height: 10 }} />
          </div>
        </div>


        <Modal
          open={this.vm.isModalOpen}
          onCancel={this.vm.closeModal}
          maskClosable={false}
          footer={null}
        >
          <div className={styles.modal}>
            新增留言
            <Input.TextArea
              value={this.vm.content}
              onChange={this.vm.onContentChnage}
              maxLength={300}
              rows={10}
              showCount
              placeholder="請輸入留言"
              className={styles.input}
            />
            <Button onClick={this.vm.createReply} className={styles.btn}>
              送出
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

CommentReply.propTypes = {
  router: PropTypes.object.isRequired,
  profile: PropTypes.object
};

export default withRouter(withProfile(CommentReply, true));
