import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './GridTable.module.scss';
import GridTableItem from './GridTableItem';


function GridTable(props) {
  return (
    <div className={styles.table}>

      {props.shortList && (
        <div className={clsx(styles.box, props.shortListClassName)}>
          {props.shortList.map((item) => (
            <GridTableItem key={item.id || item.key} title={item.key} value={item.value} labelClassName={props.labelClassName} />
          ))}
        </div>
      )}

      {props.longList && (
        <div>
          {props.longList.map((item) => (
            <GridTableItem key={item.id || item.key} title={item.key} value={item.value} />
          ))}
        </div>
      )}

    </div>
  );
}


GridTable.propTypes = {
  shortList: PropTypes.array,
  longList: PropTypes.array,
  shortListClassName: PropTypes.string,
  labelClassName: PropTypes.string
};

export default GridTable;
