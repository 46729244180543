import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

import Card from 'src/components/Card';
import { Router, FosterStatus } from 'src/constants';
import { parseDate, intersectionObserverOption } from 'src/utils';
import styles from './styles.module.scss';

@observer
class TabContent extends React.Component {
  constructor() {
    super();
    this.ref = React.createRef();
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.props.vm.onNextPage(this.props.status);
      }
    }, intersectionObserverOption);
  }

  componentDidMount() {
    this.observer.observe(this.ref.current);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  renderAdoptedCard(cat) {
    const isLate = dayjs().isAfter(dayjs(cat.nextReportingAt));
    return (
      <Card
        key={cat.id}
        image={cat?.images?.[0]}
        title={cat.name ?? '-'}
        content={(
          <div className={styles.card_content}>
            <div>{`認養申請：${cat.applicationCount}`}</div>
            <div>{`案件編號：${cat.serialNumber ?? ''}`}</div>
            <div>{`上次更新時間：${parseDate(cat.lastReportingAt, '-', true)}`}</div>
            <div className={cn({ [styles.dangerColor]: isLate })}>
              {`下次回報截止日：${parseDate(cat.nextReportingAt, '-', true)}${isLate ? '（已遲交！）' : ''}`}
            </div>
          </div>
        )}
        footer={(
          <div className={styles.endedFooter}>
            <Link to={`${Router.Client.FosterList}/${cat.id}`}>
              <Button className={styles.secondary}>出養紀錄</Button>
            </Link>
            <Link to={`${Router.Client.Report_ROOT}/${cat.id}`}>
              <Button className={styles.primary}>幸福回報</Button>
            </Link>
          </div>
        )}
        ribbon={cat.status === FosterStatus.Ended ? { label: '已結束', color: '#BE185D', shasowColor: '#831843' } : null}
      />
    );
  }

  renderOtherCard(cat) {
    const hasNewApplication = cat?.unreadApplicationIds?.length > 0;

    return (
      <Card
        key={cat.id}
        to={`${Router.Client.FosterList}/${cat.id}`}
        image={cat?.images?.[0]}
        title={cat.name ?? '-'}
        content={(
          <div className={styles.card_content}>
            <div className={styles.row}>
              <div>{`認養申請：${cat.applicationCount}`}</div>
              {hasNewApplication && <div className={styles.card_content_tag}>新申請</div>}
            </div>
            <div>{`案件編號：${cat.serialNumber ?? ''}`}</div>
          </div>
        )}
        footer={(
          <div className={styles.time}>
            最近更新時間：
            <span>{parseDate(cat.updateAt, '-')}</span>
          </div>
        )}
      />
    );
  }

  render() {
    const key = this.props.status.toLowerCase();
    const renderCard = this.props.status === FosterStatus.Adopted ? this.renderAdoptedCard : this.renderOtherCard;

    return (
      <div
        className={cn(
          styles.card_box,
          { [styles.card_box_two_col]: this.props.status !== FosterStatus.Adopted }
        )}
      >
        {this.props.vm[key].list.map((cat) => renderCard(cat))}
        <div ref={this.ref} />
      </div>
    );
  }
}

TabContent.propTypes = {
  status: PropTypes.string.isRequired,
  vm: PropTypes.object.isRequired
};

export default TabContent;
