import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Avatar } from 'antd';

import { Router } from 'src/constants';
import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import CommentPair from 'src/components/CommentPair';
import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import { intersectionObserverOption } from 'src/utils';

import CommentVM from './viewModel';
import styles from './styles.module.scss';


@observer
class Comment extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();

    const userId = this.props.router.params.userId;
    this.vm = new CommentVM(userId || this.props.profile?.id);

    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.vm.onNextPage();
      }
    }, intersectionObserverOption);
  }

  componentDidMount() {
    this.vm.didMount();
    this.observer.observe(this.ref.current);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {

    return (
      <>
        <Breadcrumb
          items={
            this.vm.userId === this.props.profile?.id
              ? [
                { title: '會員中心', link: Router.Client.User },
                { title: '我的評價' }
              ]
              : undefined
          }
        />


        <div className={styles.main}>
          <div className={styles.box}>

            {this.vm.isPageLoading
              ? <Loading />
              : (
                <>
                  <div className={styles.disclaimer}>所有評價及留言資料為個別接觸的主觀經驗，僅供關係人參考使用，請勿進行不當傳播，以免觸法。</div>

                  <div className={styles.avatarBox}>
                    <Avatar src={this.vm.user?.avatar} size={40} />
                    <div>{this.vm.user?.nickname}</div>
                  </div>


                  {this.vm.hasComment
                    ? (
                      <>
                        {this.vm.commentPairs.map((commentPair) => (
                          <CommentPair
                            userId={this.vm.user?.id}
                            commentPair={commentPair}
                            key={commentPair.id}
                          />
                        ))}
                      </>
                    )
                    : (
                      <div className={styles.noCommentBox}>
                        <div className={styles.noComment}>尚無評論</div>
                      </div>
                    )}
                </>
              )}

            <div ref={this.ref} style={{ height: 10 }} />

          </div>
        </div>
      </>
    );
  }
}

Comment.propTypes = {
  profile: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

export default withRouter(withProfile(Comment, true));
