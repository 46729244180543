import React from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Table, Input, Select, Tabs } from 'antd';
import { Router, ComplaintStatus, ComplaintStatusTxt, ComplaintLevelTxt } from 'src/constants/index';
import { parseDate } from 'src/utils';
import clsx from 'clsx';

import withProfile from 'src/components/withProfile';
import ComplaintViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class ComplaintPage extends React.Component {
  constructor() {
    super();
    this.vm = new ComplaintViewModel();
  }

  componentDidMount() {
    this.vm.didMount();
  }

  defaultColumns = [
    {
      title: '舉報時間',
      key: 'reportedAt',
      render: (data) => dayjs(data.createdAt).format('YYYY-MM-DD HH:mm')
    },
    {
      title: '被舉報對象',
      key: 'targetName',
      render: (data) => data.target.name
    },
    {
      title: '舉報人姓名/組織',
      key: 'reporter',
      render: (data) => data.reporter.name
    },
    {
      title: '舉報人暱稱',
      key: 'reporterNickname',
      render: (data) => data.reporter.nickname
    },
    {
      title: '舉報人帳號',
      key: 'reporterAccount',
      render: (data) => data.reporter.phone
    },
    {
      title: '嚴重等級',
      key: 'level',
      render: (data) => ComplaintLevelTxt[data.result.level] ?? '-'
    },
    {
      title: '調查更新時間',
      key: 'resultDate',
      width: 150,
      render: (data) => parseDate(data.result.date, '-')
    },
    {
      title: '操作',
      key: 'action',
      width: 120,
      render: (data) => (
        <Link
          type="link"
          to={`${Router.Backstage.Complaint}/${data.id}`}
          className={styles.link}
        >
          查看詳情
        </Link>
      )
    }
  ];

  getColumnsByTab = (tab) => {
    switch (tab) {
      case ComplaintStatus.NotStarted:
        return this.defaultColumns.filter((column) => column.key !== 'resultDate' && column.key !== 'level');
      case ComplaintStatus.InProcess:
        return this.defaultColumns.filter((column) => column.key !== 'level');
      default:
        return this.defaultColumns;
    }
  };

  renderTabContent = (currentStatus) => {
    return (
      <>
        <div className={styles.filterBar}>
          {currentStatus === ComplaintStatus.Completed && (
            <div className={styles.levelFilter}>
              <div className={styles.title}>
                嚴重等級：
              </div>
              <Select
                style={{ width: 90 }}
                placeholder="請選擇"
                options={Object.entries(ComplaintLevelTxt).map(([key, value]) => ({ label: value, value: key }))}
                onChange={this.vm.onLevelChange}
                allowClear
              />
            </div>
          )}
          <Input.Search
            placeholder="請輸入電話號碼或姓名"
            enterButton="搜尋"
            className={styles.input}
            onSearch={this.vm.onKeywordSearch}
            value={this.vm.params.keyword}
            onChange={(e) => this.vm.onKeywordChange(e.target.value)}
            allowClear
          />
        </div>

        <Table
          columns={this.getColumnsByTab(currentStatus)}
          dataSource={this.vm.pager.section}
          rowKey={(record) => record.id}
          pagination={{
            pageSize: this.vm.pager.limit,
            total: this.vm.pager.total,
            position: ['topRight'],
            // showSizeChanger: true,
            // pageSizeOptions: ['20', '50', '100'],
            onShowSizeChange: this.vm.pager.onLimitChange
          }}
          onChange={this.vm.pager.onPageChange}
          scroll={{ y: 'calc(100vh - 248px)', x: 'max(1000px, 100%)' }}
          className={clsx(styles.complaintTable, this.vm.pager.section?.length === 0 && styles.empty)}
        />
      </>
    );
  };

  render() {
    return (
      <div className={styles.main}>
        <div className={styles.header}>黑名單舉報管理</div>

        <Tabs
          className={styles.tabs}
          activeKey={this.vm.currentTab}
          onChange={this.vm.onTabChange}
          centered
          items={Object.entries(ComplaintStatusTxt).map(([key, value]) => (
            {
              key,
              label: value,
              children: this.renderTabContent(key)
            }
          ))}
        />
      </div>
    );
  }
}


export default withProfile(ComplaintPage, true, true);
