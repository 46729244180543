import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { parseDate, parseAge, intersectionObserverOption } from 'src/utils';
import { Router, TaiwanCountyTxt, ApplicationStatus, ApplicationStatusTxt } from 'src/constants';
import Card from 'src/components/Card';
import styles from './styles.module.scss';

@observer
class Application extends React.Component {
  constructor() {
    super();
    this.ref = React.createRef();
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.props.vm.applicationOnNextPage();
      }
    }, intersectionObserverOption);
  }

  componentDidMount() {
    this.props.vm.applicationDidMount();
    this.observer.observe(this.ref.current);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  getRibbon(application) {
    switch (application.status) {
      case ApplicationStatus.ApplicationCanceled:
      case ApplicationStatus.ApplicationDeclined:
      case ApplicationStatus.CancelFoster:
      case ApplicationStatus.CancelAdoption:
        return {
          label: ApplicationStatusTxt[application.status],
          color: '#A8A29E',
          shodowColor: '#27272A'
        };
      case ApplicationStatus.Ended:
        return {
          label: ApplicationStatusTxt[application.status],
          color: '#BE185D',
          shodowColor: '#831843'
        };
      default:
        return {
          label: ApplicationStatusTxt[application.status],
          color: '#00A5B8',
          shodowColor: '#27272A'
        };
    }
  }

  render() {
    return (
      <div className={styles.application}>
        <div className={styles.cardBox}>
          {
            this.props.vm.applications.list.map((application) => {
              const foster = application.foster;

              return (
                <Card
                  key={application.id}
                  to={`${Router.Client.ApplicationList}/${application.id}`}
                  toNewTab
                  ribbon={this.getRibbon(application)}
                  image={foster.images?.[0]}
                  title={`出養人：${foster.fosterPerson.nickname}`}
                  content={(
                    <div className={styles.cardContent}>
                      <div>{`${foster.name}${foster.birthday ? `, ${parseAge(foster.birthday)}歲` : ''}`}</div>
                      <div>{`地區：${TaiwanCountyTxt[foster.fosterPerson.region] ?? ''}`}</div>
                      <div>{`提交申請時間：${parseDate(application.submitAt)}`}</div>
                      <div>{`狀態變更時間：${parseDate(application.statusUpdateAt)}`}</div>
                    </div>
                  )}
                />
              );
            })
          }
        </div>
        <div ref={this.ref} style={{ height: 10 }} />
      </div>
    );
  }
}

Application.propTypes = {
  vm: PropTypes.object.isRequired
};

export default Application;
