import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import { ComplaintLevel } from 'src/constants';
import ComplaintService from 'src/services/complaint';

import styles from './styles.module.scss';
import ComplaintRecordCard from './ComplaintRecordCard';
import ComplaintRecordDetailModal from './ComplaintRecordDetailModal';

const ComplaintRecordsDrawer = (props) => {
  const { personId, personName, open, onClose } = props;
  const [complaintRecords, setComplaintRecords] = useState([]);
  const [detailId, setDetailId] = useState(null);


  useEffect(() => {
    const fetchComplaintRecord = async () => {
      const list = await ComplaintService.getRecords(personId);
      setComplaintRecords(list);
    };

    if (open) {
      fetchComplaintRecord();
    }
  }, [personId, open]);

  const onCloseDetailModal = () => setDetailId(null);
  const onClickViewDetail = useCallback((id) => {
    setDetailId(id);
  }, []);

  return (
    <>
      <Drawer
        title={`${personName}的被舉報紀錄`}
        width="min(460px, 90vw)"
        maskClosable
        zIndex={2}
        open={open}
        onClose={onClose}
        destroyOnClose
      >
        <div className={styles.complaintRecordList}>
          {complaintRecords.map((record) => (
            <ComplaintRecordCard
              key={record.id}
              complaint={record}
              onClickViewDetail={() => onClickViewDetail(record.id)}
            />
          ))}
        </div>
      </Drawer>

      <ComplaintRecordDetailModal
        id={detailId}
        open={!!detailId}
        onClose={onCloseDetailModal}
      />
    </>
  );
};

ComplaintRecordsDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  personName: PropTypes.string,
  personId: PropTypes.string
};

export default ComplaintRecordsDrawer;
