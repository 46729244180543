import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Outlet, useNavigate, ScrollRestoration } from 'react-router-dom';
import { Layout, Menu, Modal, Button } from 'antd';
import {
  UsergroupDeleteOutlined,
  SettingOutlined,
  SmileOutlined,
  ReadOutlined,
  StarOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Helmet } from 'react-helmet';

import { Router } from 'src/constants';
import errorStore from 'src/stores/errorStore';
import { backstageConfirmStore } from 'src/stores/confirmStore';
import logo from 'src/assets/logo.png';

import VM from './viewModel';
import styles from './styles.module.scss';

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label
  };
}

const Items = [
  getItem('會員列表', Router.Backstage.User, <UsergroupDeleteOutlined />),
  getItem('出養查詢與管理', Router.Backstage.Foster, <SettingOutlined />),
  getItem('幸福回報管理', Router.Backstage.Report, <SmileOutlined />),
  getItem('獎勵活動管理', Router.Backstage.Raffle, <StarOutlined />),
  getItem('黑名單舉報管理', Router.Backstage.Complaint, <WarningOutlined />),
  getItem('內容管理', null, <ReadOutlined />, [
    getItem('Banner', Router.Backstage.Banner),
    getItem('最新消息', Router.Backstage.News),
    getItem('關於我們', Router.Backstage.AboutUs),
    getItem('常見問題', Router.Backstage.FAQ),
    getItem('幫助中心', Router.Backstage.Help)
  ]),
  getItem('登出', Router.Auth.Login)
];

const BackstageLayout = observer(() => {
  const navigate = useNavigate();
  const [vm] = useState(() => new VM());

  useEffect(
    () => {
      vm.init(window.location.pathname);
      return () => {};
    },
    [vm]
  );

  const onMenuClick = useCallback(
    (ev) => vm.onMenuClick(ev, navigate),
    [vm, navigate]
  );

  return (
    <>
      <Helmet>
        <title>喵加人管理後台</title>
      </Helmet>

      <Layout style={{ minHeight: '100vh' }}>

        <Layout.Sider
          collapsible
          collapsed={vm.collapsed}
          onCollapse={vm.onCollapse}
        >
          <img src={logo} alt="logo" className={styles.logo} />
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={vm.selectedKeys}
            items={Items}
            onClick={onMenuClick}
          />
        </Layout.Sider>

        <Layout>
          <Layout.Content style={{ margin: '16px' }}>
            <Outlet />
          </Layout.Content>
        </Layout>

      </Layout>

      <Modal
        open={errorStore.isShow}
        title="Oops, something went wrong!"
        onCancel={errorStore.close}
        footer={<Button onClick={errorStore.close}>OK</Button>}
      >
        {errorStore.messages.map((message) => (
          <div key={message}>{message}</div>
        ))}
      </Modal>

      <Modal
        open={backstageConfirmStore.isShow}
        title={backstageConfirmStore.title}
        onCancel={backstageConfirmStore.onCancel}
        onOk={backstageConfirmStore.onOk}
        okText="確認"
        cancelText="取消"
      >
        <div>{backstageConfirmStore.message}</div>
      </Modal>

      <ScrollRestoration />
    </>
  );
});


BackstageLayout.propTypes = {

};


export default BackstageLayout;
