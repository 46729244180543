import Complaint from 'src/models/Complaint';
import { request, getHost } from './api';
import AuthService from './auth';

export default class ComplaintService {

  /**
   * [B] 後台＿黑名單＿檢舉列表
   */
  static async getListBackstage(params) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/backstage/complaint/list`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params
    };
    const res = await request(options);
    const { list, anchor: nextAnchor } = res.data;
    return {
      anchor: nextAnchor,
      list: list.map((data) => new Complaint(data))
    };
  }

  /**
   * [B] 後台＿黑名單＿檢舉詳情
   */
  static async getDetailBackstage(id) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/backstage/complaint/${id}/detail`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [B] 後台＿黑名單＿更新調查結果
   */
  static async updateResult(complaintId, data) {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/backstage/complaint/${complaintId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [B] 後台＿黑名單＿gen pre-signed urls
   */
  static async getUploadUrlsBackstage(signal, complaintId, files) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/backstage/complaint/${complaintId}/files`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      signal,
      data: {
        files
      }
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [C] 前台＿黑名單＿新增
   */
  static async create(data) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/complaint/create`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [C] 前台＿黑名單＿gen pre-signed urls
   */
  static async getUploadUrls(signal, files) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/complaint/files`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      signal,
      data: {
        files
      }
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [C] 前台＿黑名單＿已建立的檢舉列表
   * @param {Object} data
   * @param {number} data.limit
   * @param {string} data.anchor
   */
  static async getList({ limit, anchor }) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/complaint/created-list`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        limit,
        anchor
      }
    };
    const res = await request(options);
    const { list, anchor: nextAnchor } = res.data;
    return {
      anchor: nextAnchor,
      list: list.map((data) => new Complaint(data))
    };
  }

  /**
   * [C] 前台＿黑名單＿被檢舉的記錄列表
   * @param {Object} data
   * @param {number} data.limit
   * @param {string} data.anchor
   */
  static async getRecords(userId) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/complaint/records`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        user: userId
      }
    };
    const res = await request(options);
    return res.data?.list?.map((d) => Complaint.fromRes(d)) ?? [];
  }


  /**
   * [C] 前台＿黑名單＿檢舉詳情
   */

  static async getDetail(complaintId) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/complaint/${complaintId}/detail`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };

    const res = await request(options);
    return res.data;
  }
}
