import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './styles.module.scss';

function Breadcrumb(props) {
  const { items = [], className = '' } = props;

  if (!items.length) {
    return null;
  }

  return (
    <div className={clsx(styles.breadcrumb, className)}>
      <div className={styles.breadcrumb_box}>

        {items.map((item, index) => {
          if (index + 1 === items.length) {
            return <span key={item.title} className={styles.dark}>{item.title}</span>;
          }
          return <Link key={item.title} to={item.link} className={styles.light}>{`${item.title} / `}</Link>;
        })}

      </div>
    </div>
  );
}

Breadcrumb.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string
};

export default Breadcrumb;
