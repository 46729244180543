import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Select, Form, Input } from 'antd';
import { TaiwanCountyTxt } from 'src/constants';

import styles from './styles.module.scss';

@observer
class Profile extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    this.props.vm.profileDidMount(this.props.form);
  }

  render() {
    return (
      <div className={styles.profile}>
        <Form form={this.props.form} className={styles.form}>

          <Form.Item name="isAdmin" label="角色" rules={[{ required: true }]}>
            <Select>
              <Select.Option value>管理者</Select.Option>
              <Select.Option value={false}>一般用戶</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="isBlock" label="狀態" rules={[{ required: true }]}>
            <Select
              options={[
                { value: false, label: '啟用' },
                { value: true, label: '停權' }
              ]}
            />
          </Form.Item>

          <Form.Item name="phone" label="手機(帳號)" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
          <Form.Item name="name" label="姓名／組織名稱" rules={[{ required: true }]}>
            <Input maxLength={50} />
          </Form.Item>
          <Form.Item name="nickname" label="暱稱" rules={[{ required: true }]}>
            <Input maxLength={20} />
          </Form.Item>
          <Form.Item name="region" label="地區" rules={[{ required: true }]}>
            <Select
              options={
                Object.keys(TaiwanCountyTxt).map((TaiwanCounty) => (
                  { value: TaiwanCounty, label: TaiwanCountyTxt[TaiwanCounty] }
                ))
              }
            />
          </Form.Item>
          <Form.Item name="email" label="email" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="contactNumber" label="備用聯絡電話">
            <Input />
          </Form.Item>
          <Form.Item name="idNumber" label="身份證字號／護照號碼／統一編號" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="selfIntro" label="簡介">
            <Input.TextArea
              rows={6}
              showCount
              maxLength={300}
            />
          </Form.Item>

        </Form>

        <Button type="primary" onClick={this.props.vm.profileUpdate}>
          儲存
        </Button>
      </div>
    );
  }
}

Profile.propTypes = {
  vm: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};


const ProfileHOC = (props) => {
  const [form] = Form.useForm();
  return (
    <Profile
      form={form}
      {...props}
    />
  );
};

export default ProfileHOC;
