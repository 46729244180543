import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import CommentPair from 'src/components/CommentPair';
import { intersectionObserverOption } from 'src/utils';
import styles from './styles.module.scss';

@observer
class Comment extends React.Component {
  constructor() {
    super();
    this.ref = React.createRef();
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.props.vm.commentOnNextPage();
      }
    }, intersectionObserverOption);
  }

  componentDidMount() {
    this.props.vm.commentDidMount();
    this.observer.observe(this.ref.current);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    return (
      <div className={styles.comment}>

        {this.props.vm.comments.list.map((commentPair) => (
          <div style={{ background: '#FFF' }} key={commentPair.id}>
            <CommentPair
              userId={this.props.vm.userId}
              commentPair={commentPair}
              toNewTab
            />
          </div>
        ))}

        <div ref={this.ref} style={{ height: 10 }} />
      </div>
    );
  }
}

Comment.propTypes = {
  vm: PropTypes.object.isRequired
};

export default Comment;
