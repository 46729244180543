import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { Router } from 'src/constants';
import UserService from 'src/services/user';

export default class VM {

  @observable collapsed = false;
  @observable selectedKeys = null;

  constructor() {
    makeObservable(this);
  }

  @action onCollapse = (value) => {
    this.collapsed = value;
  };

  onMenuClick = async ({ key }, navigate) => {
    if (key === Router.Auth.Login) {
      await UserService.logout();
      navigate(Router.Auth.Login);

    } else {
      runInAction(() => { this.selectedKeys = [key]; });
      navigate(key);
    }
  };

  @action init = (path) => {
    const keys = [
      Router.Backstage.User,
      Router.Backstage.Foster,
      Router.Backstage.Report,
      Router.Backstage.Raffle,
      Router.Backstage.Complaint,
      Router.Backstage.Banner,
      Router.Backstage.News,
      Router.Backstage.AboutUs
    ];

    for (let i = 0; i < keys.length; i += 1) {
      if (path.includes(keys[i])) {
        this.selectedKeys = [keys[i]];
        break;
      }
    }

  };
}
