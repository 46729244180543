import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Modal, Input } from 'antd';

import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import NoPermission from 'src/components/NoPermission';
import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import UploadWithCrop from 'src/components/UploadWithCrop';
import ReactionFlag from 'src/components/ReactionFlag';
import ReactionRow from 'src/components/ReactionRow';
import MemberOnlyModal from 'src/components/MemberOnlyModal';
import { parseDate, intersectionObserverOption } from 'src/utils';
import { Router, TaiwanCountyTxt } from 'src/constants';

import ReportVM from './viewModel';
import styles from './styles.module.scss';


@observer
class ReportPage extends React.Component {
  constructor(props) {
    super(props);

    const fosterId = this.props.router.params.fosterId;
    this.ref = React.createRef();
    this.vm = new ReportVM(fosterId, this.props.profile);
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.vm.getList();
      }
    }, intersectionObserverOption);
  }

  componentDidMount() {
    this.vm.didMount(this.props.router);
  }

  renderTag = () => {
    const foster = this.vm.foster;
    if (!foster || this.vm.isEnded || !foster.needReport) return null;

    return <div className={styles.tag}>{this.vm.reportFrequency ? `每${this.vm.reportFrequency}天回報` : '自動回報'}</div>;
  };

  getNextReportAt() {
    if (this.vm.isEnded) return '已結束';
    if (!this.vm.foster?.needReport || this.vm.reportFrequency === 0) return '-';
    return parseDate(this.vm.foster?.nextReportingAt, '-', true);
  }

  onRef = (ref) => {
    if (this.ref.current) {
      this.observer.unobserve(this.ref.current);
    }

    if (ref) {
      this.ref.current = ref;
      this.observer.observe(this.ref.current);
    }
  };

  render() {
    if (this.vm.isPageLoading || !this.vm.hasPermission) {
      return (
        <>
          {this.vm.isPageLoading && <Loading />}
          {!this.vm.isPageLoading && <NoPermission callback={() => this.props.router.navigate(Router.Client.Home)} />}
          <div ref={this.onRef} />
        </>
      );
    }


    const foster = this.vm.foster;
    const profile = this.props.profile;
    const canReport = (profile?.isAmin || this.vm.foster?.adopter?.id === profile?.id) && !this.vm.isEnded;

    return (
      <>
        <Breadcrumb
          items={[
            { title: '會員中心', link: Router.Client.User },
            { title: `${foster?.name ?? '-'}的幸福回報` }
          ]}
        />

        <div className={styles.main}>
          <div className={styles.box}>
            <div className={styles.leftCol}>
              <div className={styles.imgBox}>
                <img className={styles.img} src={foster?.images?.[0]} alt="cat" />
              </div>

              <div className={styles.metaBox}>
                {this.renderTag()}
                <div className={styles.name}>{foster?.name ?? 'name'}</div>
                <div>{`出養人：${foster?.fosterPerson.name || foster?.fosterPerson.nickname || '-'}`}</div>
                <div>{`地區：${TaiwanCountyTxt[foster?.fosterPerson.region]}`}</div>
                <div className={styles.primaryColor}>{`上次更新時間：${parseDate(foster?.lastReportingAt, '-', true)}`}</div>
                <div className={cn({ [styles.dangerColor]: this.vm.isLate })}>
                  {`下次回報截止日：${this.getNextReportAt()}`}
                </div>

                {canReport && (
                <Button className={styles.btn} onClick={this.vm.openCreateModal}>新增回報</Button>
                )}
              </div>
            </div>

            <div className={styles.rightCol}>
              <div className={styles.cardBox}>
                {this.vm.list.map((report) => (
                  <div
                    key={report.id}
                    className={styles.imgBox}
                    onClick={() => this.vm.openDataModal(report)}
                  >
                    <img className={styles.img} src={report.image} alt="cat" />
                    <ReactionFlag
                      size="small"
                      item={report}
                      onReaction={this.vm.onReaction}
                    />
                  </div>
                ))}
              </div>

              {canReport && (
              <Button className={styles.blockBtn} onClick={this.vm.openCreateModal}>新增回報</Button>
              )}
            </div>
            <div ref={this.onRef} style={{ height: 10 }} />
          </div>
        </div>


        <Modal
          open={this.vm.isCreateModalShow}
          maskClosable={false}
          footer={null}
          width="min(800px, 80%)"
          centered
          onCancel={this.vm.closeCreateModal}
          destroyOnClose
        >
          <div className={styles.createModal}>
            <div className={styles.name}>{`認養人：${this.vm.foster?.adopter?.nickname ?? '-'}`}</div>
            <div className={styles.content}>

              <div className={styles.upload}>
                <div style={{ flex: 1 }}>
                  <UploadWithCrop
                    maxCount={1}
                    onChange={this.vm.handleUploadChange}
                    type="dragger"
                    itemRender={() => {}}
                  />
                </div>

                {this.vm.imageUrl && (
                  <img src={this.vm.imageUrl} alt="preview" className={styles.uploadImg} />
                )}

              </div>

              <Input.TextArea
                placeholder="請輸入留言"
                onChange={this.vm.onMessageChange}
                value={this.vm.content}
                showCount
                maxLength={100}
                className={styles.input}
              />
            </div>
            <Button
              className={styles.modalBtn}
              onClick={this.vm.createReport}
              loading={this.vm.isCreating}
            >
              送出
            </Button>
          </div>
        </Modal>


        <Modal
          open={this.vm.isDataModalShow}
          footer={null}
          width="min(600px, 90vw)"
          centered
          onCancel={this.vm.closeDataModal}
        >
          <div className={styles.dataModal}>
            <div className={styles.name}>{`認養人：${this.vm.modalData?.adopter ?? '-'}`}</div>
            <div className={styles.content}>
              <img className={styles.img} src={this.vm.modalData?.image} alt="cat" />
              <ReactionRow
                item={this.vm.modalData}
                sharePath={`/success-stories?id=${this.vm.modalData?.id}`}
                onReaction={this.vm.onReaction}
              />
              <div className={styles.content}>{this.vm.modalData?.content}</div>
              <div className={styles.createAt}>{`幸福回報時間：${parseDate(this.vm.modalData?.createAt, '-')}`}</div>
            </div>
          </div>
        </Modal>

        <MemberOnlyModal
          open={this.vm.isMemberOnlyModalShow}
          onClose={this.vm.closeMemberOnlyModal}
        />
      </>
    );
  }
}

ReportPage.propTypes = {
  router: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

export default withRouter(withProfile(ReportPage, true));


